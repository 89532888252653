<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Create Video Zone</strong>
            </div>
            <div class="text-muted">Create new Video Zone</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Publisher">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Publisher of the Video Zone.</span>
                      </template>
                      <a-select
                        v-decorator="['publisher', { initialValue: initialValue, rules: [{ required: true, message: 'Please select publisher' }] }]"
                        placeholder="Please select a publisher" :showSearch="true" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option v-for="pub in ORTBpublisher.list.filter(ele => ele.Status === 0)"
                          :value="pub.Id" :key="pub.Id">[{{
                            pub.Id }}] {{ pub.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Video Zone's name.</span>
                      </template>
                      <a-input type="text" placeholder="Video Zone Name" v-decorator="['name', {
                        rules: [
                          { required: true, message: 'Name is required.' },
                          { max: 64, message: 'Maximum 64 characters allowed.' }
                        ]
                      }]" />
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Size">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Video player's size in pixels.</span>
                      </template>
                      <a-select
                        v-decorator="['videoSize', { initialValue: 0, rules: [{ required: false, message: 'Please select a size' }] }]"
                        placeholder="Video Size" :showSearch="true" :filterOption="true" optionFilterProp="children"
                        @change="isCustomSizeCheck">
                        <a-select-option :key="index" v-for="(size, index) in videoSizes">{{
                          size.label
                        }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item v-if="isCustomSize" :label-col="labelCol" :wrapper-col="wrapperCol" label="Width">
                    <a-input min="0" max="9999" style="width: 100%" placeholder="Width" v-decorator="['Width',
                      {
                        type: 'number', initialValue: 0, rules: [
                          { required: isCustomSize, message: 'Width is required.' }]
                      }]" />
                  </a-form-item>

                  <a-form-item v-if="isCustomSize" :label-col="labelCol" :wrapper-col="wrapperCol" label="Height">
                    <a-input min="0" max="9999" style="width: 100%" placeholder="Height" v-decorator="['Height',
                      {
                        type: 'number', initialValue: 0, rules: [
                          { required: isCustomSize, message: 'Height is required.' }]
                      }]" />
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bidfloor">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Minimum bid expressed in CPM. Value will be used as a bidfloor in RTB auctions.</span>
                      </template>
                      <a-input type="float" min="0" max="100" step="0.0001" placeholder="Bidfloor"
                        v-decorator="['bidfloor', { initialValue: 0.500, rules: [{ validator: cpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Margin">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Margin percentage to add to bid floor which is sent to advertiser's Remote RTB Zone. For
                          example, if publisher's bid floor is $5 and Margin is 20%, advertiser will receive $6 bid
                          floor.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%" v-decorator="['margin', { initialValue: 0 }]"
                        placeholder="Margin">
                      </a-input>
                      <!-- <a-input  /> -->
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Publisher Revenue Share">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Percentage of a revenue (bid price minus bid floor) from Remote RTB Zone's advertiser that
                          Video Zone's publisher earns.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%"
                        v-decorator="['publisherRevShare', { initialValue: 100 }]"
                        placeholder="Publisher Revenue Share">
                      </a-input>
                      <!-- <a-input  /> -->
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Domains/Brands Blacklist">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of domains or brand names to block.</span>
                      </template>
                      <div class="list-flex">
                        <a-select v-model="adDomainsBrandsBlacklist" placeholder="Ad Domain/Brands Blacklist"
                          :filterOption="true" :showSearch="true" optionFilterProp="children" mode="tags"
                          :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('brands')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Apps Blacklist">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Blacklist of applications by their application identifiers. On Android, these should be
                          bundle or package names (e.g., com.foo.mygame), on iOS, these are numeric IDs.</span>
                      </template>
                      <div class="list-flex">
                        <a-select v-model="adAppsBlacklist" placeholder="Ad Apps Blacklist" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info mr-1 copy-button-width" @click="copyList('apps')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Keywords">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of keywords describing the site/app.</span>
                      </template>
                      <div class="list-flex">
                        <a-select v-model="keywords" placeholder="Keywords" :showSearch="true" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('keywords')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>

                </div>
                <div class="w-100">

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Site Domain">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Domain of the site.</span>
                      </template>
                      <a-input type="text"
                        v-decorator="['SiteDomain', { rules: [{ max: 64, message: 'Maximum 64 characters allowed.' }] }]"
                        placeholder="Site Domain" />
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>App name.</span>
                      </template>
                      <a-input type="text"
                        v-decorator="['AppName', { rules: [{ max: 64, message: 'Maximum 64 characters allowed.' }] }]"
                        placeholder="App Name" />
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Bundle">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>A platform-specific application identifier intended to be unique to the app and
                          independent of the exchange. On Android, these should be bundle or package names (e.g.,
                          com.foo.mygame), on iOS, these are numeric IDs.</span>
                      </template>
                      <a-input type="text"
                        v-decorator="['AppBundle', { rules: [{ max: 64, message: 'Maximum 64 characters allowed.' }] }]"
                        placeholder="App Bundle" />
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Store URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>App Store URL for an installed app.</span>
                      </template>
                      <a-input type="url"
                        v-decorator="['AppStoreUrl', { rules: [{ max: 256, message: 'Maximum 256 characters allowed.' }] }]"
                        placeholder="App Store URL" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Tmax">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum time in milliseconds to wait for the response from Remote RTB Zone's
                          server.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Tmax" v-decorator="['tmax', { initialValue: 350 }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Auction Type">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Type of RTB auction within the platform.</span>
                      </template>
                      <a-select v-decorator="['auctionType', { initialValue: '2' }]" placeholder="Auction Type"
                        :filterOption="true" optionFilterProp="children">
                        <a-select-option value='1'>First Price</a-select-option>
                        <a-select-option value='2'>Second Price Plus</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Linearity">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Indicates if the impression must be linear or nonlinear.</span>
                      </template>
                      <a-select placeholder="Select Linearity" v-decorator="['linearity', {
                        initialValue: -1,
                      }]">
                        <a-select-option :value="-1">All</a-select-option>
                        <a-select-option :value="1">Linear</a-select-option>
                        <a-select-option :value="2">Non-Linear</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Placement">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Video placement type.</span>
                      </template>
                      <a-select placeholder="Select Placement" v-decorator="['Placement', {
                        initialValue: 1,
                      }]">
                        <a-select-option :value="-1">All</a-select-option>
                        <a-select-option :value="1">In-Stream</a-select-option>
                        <a-select-option :value="2">In-Banner</a-select-option>
                        <a-select-option :value="3">In-Article</a-select-option>
                        <a-select-option :value="4">In-Feed</a-select-option>
                        <a-select-option :value="5">Interstitial/Slider/Floating</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Secure">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Indicates if the Video Zone requires secure HTTPS URL creative assets and markup.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="secureValue" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Waterfall">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Follow additional VAST wrappers if no ad is returned.</span>
                      </template>
                      <a-switch checked-children="Enabled" un-checked-children="Disabled" v-model="waterfallValue" />
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Ad Position</a-divider>
              <Bannerposition v-on:setPosition="setAdPosition($event)" :positionKey="adPosition" />
              <a-divider style="margin-top: 34px;">Limits</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Requests Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of requests to receive per day. The system will even out distribution of requests
                          throughout the day, ie. "day shaping". Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Requests Daily Limit"
                        v-decorator="['requestsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of bids per day. The system will even out distribution of bids throughout the day,
                          ie. "day shaping". Set to 0 for unlimited bids.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Bids Daily Limit"
                        v-decorator="['bidsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of impressions per day. The system will even out distribution of impressions
                          throughout the day, ie. "day shaping". Set to 0 for unlimited impressions.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Spent Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum daily amount (USD) to spend on Video Zone. Set to 0 for unlimited amount.</span>
                      </template>
                      <a-input type="number" min="0" step="0.0001" placeholder="Spent Daily Limit"
                        v-decorator="['spentDailyLimit', { initialValue: 0, rules: [{ validator: cpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Supply Filters</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol"
                    label="Domains/Brands List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow publisher domains or brand names.</span>
                      </template>
                      <a-select v-decorator="['domainsBrandsListMode', { initialValue: 0 }]"
                        placeholder="Domains/Brands List Mode" :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Domains/Brands List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Domain Lists to block or allow.</span>
                      </template>
                      <a-select v-decorator="['DomainsBrandsList', { initialValue: [] }]"
                        placeholder="Please Select Domains/Brands Lists" :showSearch="true" :filterOption="true"
                        mode="multiple" optionFilterProp="children">
                        <a-select-option v-for="obj in activeDomainLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                      <!-- <div class="list-flex">
                        <a-select v-model="domainsBrandsList" placeholder="Domains/Brands List" :showSearch="true"
                          :filterOption="true" optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-secondary copy-button-width" @click="copyList('brands')"><i
                            class="fa fa-copy"></i></a-button>
                      </div> -->
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Apps List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow publisher applications by their application identifiers.</span>
                      </template>
                      <a-select v-decorator="['appsListMode', { initialValue: 0 }]" placeholder="Apps List Mode"
                        :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Apps List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of App Lists to block or allow.</span>
                      </template>
                      <a-select v-decorator="['AppsList', { initialValue: [] }]" placeholder="Please Select App Lists"
                        :showSearch="true" :filterOption="true" mode="multiple" optionFilterProp="children">
                        <a-select-option v-for="obj in activeAppLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="Sizes List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow creatives' sizes.</span>
                      </template>
                      <a-select v-model="sizesListMode" placeholder="Blacklist" defaultValue="0" :filterOption="true"
                        optionFilterProp="children">
                        <a-select-option value='0'>Blacklist</a-select-option>
                        <a-select-option value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item class="mt-10" :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol"
                    label="Sizes List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of creatives' sizes to block or allow.</span>
                      </template>
                      <a-select v-decorator="['sizesList']" placeholder="Please select Sizes" :showSearch="true"
                        :filterOption="true" mode="multiple" optionFilterProp="children">
                        <a-select-option :value="`${size.size.width}x${size.size.height}`"
                          v-for="(size, index) in sizes" :key="index">{{ size.size.width }}x{{ size.size.height }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="CRID List Mode">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Block or allow Creative IDs</span>
                      </template>
                      <a-select v-decorator="['cridListMode', { initialValue: 0 }]" placeholder="CRID List Mode"
                        :filterOption="true" optionFilterProp="children">
                        <a-select-option :value='0'>Blacklist</a-select-option>
                        <a-select-option :value='1'>Whitelist</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="CRID List">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of Creative IDs to block or allow.</span>
                      </template>
                      <div class="list-flex">
                        <a-select v-model="cridList" placeholder="CridList" :showSearch="true" :filterOption="true"
                          optionFilterProp="children" mode="tags" :tokenSeparators="[' ']">
                        </a-select>
                        <a-button class="btn-info copy-button-width" @click="copyList('crids')"><i
                            class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="supplyLabelCol" :wrapper-col="supplyWrapperCol" label="IP Blacklists">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of IP Lists to use for IP block. Only "Not Global" IP Lists can be chosen.</span>
                      </template>
                      <a-select v-decorator="['ipBlacklists', { initialValue: [] }]"
                        placeholder="Please select IP Lists" :showSearch="true" :filterOption="true" mode="multiple"
                        optionFilterProp="children">
                        <a-select-option v-for="obj in activeIpLists" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Categories & Ad Attributes</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Enable IAB Categories">
                    <a-tree checkable v-model="checkedKeys" :auto-expand-parent="autoExpandParent"
                      :selected-keys="selectedKeys" :tree-data="treeData" @expand="onExpand" @select="onSelect"
                      optionFilterProp="children" />
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Blocked Ad IAB Categories">
                    <a-tree checkable v-model="checkedBlockedKeys" :auto-expand-parent="autoExpandParent"
                      :selected-keys="selectedBlockedKeys" :tree-data="treeData" @expand="onExpand"
                      @select="onBlockSelect" optionFilterProp="children" />
                    <!-- <a-select
                      v-decorator="['BlockedIAB', { initialValue: undefined }]"
                      placeholder="IAB Categories"
                      :showSearch="true"
                      :filterOption="true"
                      mode="tags"
                      optionFilterProp="children"
                    >
                      <a-select-option v-for="category in IabCategories" :key="category.Name" >{{category.Name}}</a-select-option>
                    </a-select> -->
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Blocked Ad Attributes">
                    <a-tree checkable class="break-line" v-model="checkedBlockedAds" :selected-keys="selectedAdKeys"
                      :tree-data="adTreeData" @expand="onExpand" @select="onBlockedAdSelect"
                      optionFilterProp="children" />
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Targeting</a-divider>
              <a-tabs default-active-key="1" class="mb-4">
                <a-tab-pane key="1" tab="Countries">
                  <a-row>
                    <a-input-search placeholder="Filter Countries" style="width: 200px" class="mb-2"
                      v-model="filterSearch" />
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half"
                        v-model="displayEnabledCountries" /> Display
                      Only Enabled</label>
                    <label class="cpointer"><input type="checkbox" class="ml-5 cpos-half" v-model="selectAllCountries"
                        @click="handleCountries" /> De/Select All</label>
                  </a-row>
                  <a-spin class="d-flex justify-content-center" v-if="enabledCountries.length === 0">
                    <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                  </a-spin>
                  <ul v-else class="list-group list-group-flush countryList" style="border: 1px solid #e4e9f0;">
                    <li class="list-group-item treeContainer" v-for="(item, index) in enabledCountries" :key="index"
                      :style="item.Enabled ? 'background-color: rgb(237 237 243 / 38%);' : ''">
                      <label class="col-sm cpos cpointer">
                        <input type="checkbox" class="global-checkbox" v-model="item.Enabled"
                          @click="enableCountry($event, item)" />
                        <span class="col-sm"><img :src="matchIcon(countries, item.CC)" /> {{ item.Country }} ({{
                          item.CC
                        }})</span>
                      </label>
                    </li>
                  </ul>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Device Types">
                  <ul class="list-group list-group-flush countryList" style="border: 1px solid #e4e9f0;">
                    <li v-for="item in deviceTypes" :key="item.index" class="list-group-item treeContainer"
                      :style="activeDeviceTypes.includes(item.value) ? 'background-color: rgb(237 237 243 / 38%);' : ''">
                      <label class="col-sm cpos cpointer">
                        <input type="checkbox" class="global-checkbox" :checked="enabledDevice(item.value)"
                          @click="enableDevice($event, item.value)" />
                        <span class="col-sm">{{ item.name }}</span>
                      </label>
                    </li>
                  </ul>
                </a-tab-pane>
              </a-tabs>

              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Create Video Zone</button>
                  <button type="button" @click="$router.push({ name: 'videozones' })"
                    class="btn btn-light px-5 ml-2">Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { getIabCategories } from '@/api/info'
import videoSizes from '@/views/display/data/zoneSize.json'
import adTreeData from '@/views/display/data/blockedAds.json'
import zoneSize from '@/views/display/data/zoneSizeRemoteRTBZones.json'
import staticCountryData from '@/resources/countries.json'

export default {
  components: {
    Bannerposition: () => import('@/components/custom/banner-position/index.vue'),
  },
  computed: {
    ...mapState(['ORTBpublisher', 'info']),
    ...mapGetters('domainlist', ['getDomainLists', 'getActiveDomainLists']),
    ...mapGetters('applist', ['getAppLists', 'getActiveAppLists']),
    ...mapGetters('ORTBiplist', ['getIpLists', 'getActiveIpLists']),
    // initialValue () {
    //   if (this.$route.params.publisherId) {
    //     return this.ORTBpublisher.list.filter(ele => ele.Id === parseInt(this.$route.params.publisherId))[0].Id
    //   } else { return 'Please select publisher' }
    // },
    initialValue() {
      if (this.$route.params.publisherId) {
        const publisher = this.ORTBpublisher.list.find(ele => ele.Id === parseInt(this.$route.params.publisherId))
        return publisher === undefined ? [] : publisher.Id
      } else {
        return []
      }
    },
    activeDomainLists: function () {
      return this.getActiveDomainLists.filter(el => el.Global === 0)
    },
    activeAppLists: function () {
      return this.getActiveAppLists.filter(el => el.Global === 0)
    },
    cridListString() {
      return this.cridList.join('\n')
    },
    activeIpLists: function () {
      return this.getActiveIpLists.filter(el => el.Global === 0)
    },
    enabledCountries() {
      let filteredCountries = this.countriesList
      if (this.displayEnabledCountries) {
        filteredCountries = filteredCountries.filter(el => el.Enabled)
      }
      if (this.filterSearch) {
        filteredCountries = filteredCountries.filter(el =>
          el.Country.toLowerCase().includes(this.filterSearch.toLowerCase()) || el.CC.toLowerCase().includes(this.filterSearch.toLowerCase()),
        )
      }
      return filteredCountries
    },
    activeKwlist() {
      return this.$store.getters['kwlist/getActiveKwLists']
    },
    keywordString() {
      return this.keywords.join()
    },
    adAppsBlacklistString() {
      return this.adAppsBlacklist.join('\n')
    },
    adDomainsBrandsBlacklistString() {
      return this.adDomainsBrandsBlacklist.join('\n')
    },
  },
  data() {
    return {
      sizes: zoneSize,
      filterSearch: '',
      secureValue: true,
      waterfallValue: true,
      keywords: [],
      adDomainsBrandsBlacklist: [],
      unformattedResponse: [],
      adAppsBlacklist: [],
      treeData: [],
      adTreeData: adTreeData,
      autoExpandParent: false,
      sizesListMode: undefined,
      checkedBlockedAds: [],
      selectedAdKeys: [],
      adPosition: -1,
      cridList: [],
      checkedKeys: [],
      countriesPerPage: 50,
      countries: staticCountryData,
      deviceTypes: [
        {
          name: 'Desktop',
          value: 1,
        },
        {
          name: 'Mobile',
          value: 2,
        },
        {
          name: 'Tablet',
          value: 3,
        },
        {
          name: 'CTV',
          value: 4,
        },
        {
          name: 'Other',
          value: 5,
        },
      ],
      activeDeviceTypes: [1, 2, 3, 4, 5],
      selectedKeys: [],
      countriesList: [],
      checkedBlockedKeys: [],
      selectedBlockedKeys: [],
      videoSizes: videoSizes,
      isCustomSize: false,
      selectAllCountries: true,
      displayEnabledCountries: false,
      form: this.$form.createForm(this),
      keywordListsType: [
        {
          Id: 0,
          Name: 'Exact',
        },
        {
          Id: 1,
          Name: 'Phrase',
        },
        {
          Id: 2,
          Name: 'Broad',
        },
      ],
      IabCategories: [],
      keywordLists: [],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      supplyLabelCol: {
        xs: { span: 18 },
        sm: { span: 5 },
      },
      supplyWrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },

    }
  },
  watch: {
    checkedKeys(val) {
      // console.log('onCheck', val)
    },
  },
  methods: {
    handleCountries() {
      this.selectAllCountries = !this.selectAllCountries
      this.countriesList.forEach(el => {
        el.Enabled = this.selectAllCountries
      })
    },
    enableCountry(event, row) {
      const itemIndex = this.countriesList.findIndex(el => el.CC === row.CC)
      if (itemIndex !== -1) {
        this.countriesList[itemIndex].Enabled = event.target.checked
      }
    },
    enableDevice(event, value) {
      const index = this.activeDeviceTypes.indexOf(value)
      if (index === -1) {
        this.activeDeviceTypes.push(value)
      } else {
        this.activeDeviceTypes.splice(index, 1)
      }
    },
    populateCountry: function () {
      if (this.countriesList.length > 0 && this.countriesList !== null && this.countriesList.length > 0) {
        for (const index in this.countriesList) {
          const country = this.countriesList.filter(ob => ob.CC === this.countriesList[index].CC)
          if (typeof country[0] !== 'undefined') {
            this.countriesList[index].Enabled = true
          }
        }
      }
    },
    enabledDevice(value) {
      if (this.activeDeviceTypes.includes(value)) {
        return true
      } else {
        return false
      }
    },
    setAdPosition(key) {
      this.adPosition = key
    },
    copyList(type) {
      if (type === 'apps') {
        navigator.clipboard.writeText(this.adAppsBlacklistString)
        Vue.prototype.$notification.success({
          message: 'Ad Apps Blacklist copied to clipboard.',
        })
      } else if (type === 'brands') {
        navigator.clipboard.writeText(this.adDomainsBrandsBlacklistString)
        Vue.prototype.$notification.success({
          message: 'Ad Domains/Brands Blacklist copied to clipboard.',
        })
      } else if (type === 'keywords') {
        navigator.clipboard.writeText(this.keywords.join('\n'))
        Vue.prototype.$notification.success({
          message: 'Keywords copied to clipboard.',
        })
      } else if (type === 'crids') {
        navigator.clipboard.writeText(this.cridList)
        Vue.prototype.$notification.success({
          message: 'CRID List copied to clipboard.',
        })
      }
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) {
        return false
      } else {
        const image = cc.image
        return image
      }
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // TODO: Maybe validate AdDomainsBrandsBlacklist
          if (this.isCustomSize === false && this.videoSizes[values.videoSize] !== undefined) {
            const temp = this.videoSizes[values.videoSize].size
            delete values.videoSize
            values.width = temp.width
            values.height = temp.height
          } else {
            values.width = this.width
            values.height = this.height
          }
          const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          const newArr = this.checkedKeys.filter(el => !array.includes(el))

          const parentSelectedBlockedIab = this.checkedBlockedKeys.filter(checkedBlockedKey => this.unformattedResponse.includes(checkedBlockedKey))
          const blockedIabArray = this.treeData.map(blockedElement => parentSelectedBlockedIab.includes(blockedElement.key) ? blockedElement.children : []).flat().map(el => el.key)
          const newBlockedArray = this.checkedBlockedKeys.filter(blockedEl => !blockedIabArray.includes(blockedEl))
          values.CridList = this.cridListString
          this.sizesListMode === undefined ? values.SizesListMode = 0 : values.SizesListMode = 1
          values.AdPosition = this.adPosition
          values.IabCategories = newArr
          values.Secure = this.secureValue ? 1 : 0
          values.Waterfall = this.waterfallValue ? 1 : 0
          values.BlockedAdIabCategories = newBlockedArray
          values.BlockedAdAttributes = this.checkedBlockedAds
          values.Keywords = this.keywordString
          values.AdAppsBlacklist = this.adAppsBlacklistString
          values.AdDomainsBrandsBlacklist = this.adDomainsBrandsBlacklistString
          values.DeviceTypes = this.activeDeviceTypes
          const countries = []
          this.countriesList.forEach(el => {
            if (el.Enabled) {
              countries.push({
                CC: el.CC,
                BidAdjustment: 100,
              })
            }
          })
          values.Countries = countries
          values.IabCategories = newArr

          if (values.AppStoreUrl !== '' && values.AppStoreUrl) {
            values.AppStoreUrl = values.AppStoreUrl.replace(/ /g, '')
          }
          this.$store.dispatch('videozone/CREATE_VIDEOZONE', {
            payload: values,
            callback: function () {
              this.$router.push({ name: 'videozones' })
            }.bind(this),
          })
        }
      })
    },
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onCheck(checkedKeys) {
      // console.log('onCheck', checkedKeys)
      this.checkedKeys = checkedKeys
    },
    onSelect(selectedKeys, info) {
      // console.log('onSelect', info)
      this.selectedKeys = selectedKeys
    },
    onBlockedAdCheck(checkedBlockedAds) {
      // console.log('onCheck', checkedBlockedAds)
      this.checkedBlockedAds = checkedBlockedAds
    },
    onBlockedAdSelect(selectedAdKeys, info) {
      // console.log('onSelect', info)
      this.selectedAdKeys = selectedAdKeys
    },
    onBlockCheck(checkedBlockedKeys) {
      // console.log('onCheck', checkedBlockedKeys)
      this.checkedBlockedKeys = checkedBlockedKeys
    },
    onBlockSelect(selectedBlockedKeys, info) {
      // console.log('onSelect', info)
      this.selectedBlockedKeys = selectedBlockedKeys
    },
    toggleCustomInput() {
      this.showCustomInput = true
    },
    isCustomSizeCheck(val) {
      this.isCustomSize = val === (this.videoSizes.length - 2)
    },
    cpmValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Value can not be negative.')
      }
      message()
    },
  },
  created() {
    getIabCategories().then(response => {
      response.forEach(element => {
        this.unformattedResponse.push(element.Name)
      })
      const formattedResponse = []
      response.forEach((el, id) => {
        const temp = {
          title: '(' + el.Name + ')' + ' ' + el.Title,
          key: el.Name,
          children: [],
        }
        el.Subcategories.forEach((element) => {
          temp.children.push({
            title: '(' + element.Name + ')' + ' ' + element.Title,
            key: element.Name,
          })
        })
        formattedResponse.push(temp)
        this.treeData = formattedResponse
      })
    })
    this.$store.dispatch('info/LOAD_COUNTRIES').then((res) => {
      res.forEach((el) => {
        this.countriesList.push({
          Country: el.Country,
          CC: el.CC,
          Enabled: false,
        })
      })
      this.populateCountry()
    })
    this.$store.dispatch('ORTBpublisher/LOAD_PUBLISHERS_SHORT')
    this.$store.dispatch('domainlist/LOAD_DOMAINLISTS')
    this.$store.dispatch('applist/LOAD_APPLISTS')
    this.$store.dispatch('ORTBiplist/LOAD_IPLISTS')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}

.list-flex {
  display: flex;
  align-content: flex-start;
}

.select-width {
  width: 90%;
}

.copy-button-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem !important;
  height: 2.1rem !important;
}

.ant-form-item {
  margin-bottom: 5px !important;
}

.custom-table th,
.custom-table td {
  padding: 0 0 0 6px !important;
  height: 30;
  text-align: center;
}

.no-records-to-show {
  text-align: center;
  text-decoration: none;
  font-weight: normal;
}

.ant-table-small {
  border: none !important;
}

/** */
.arrow.left::before {
  content: "▶";
  height: 10px;
  margin-right: 5px;
}

.arrow.down::before {
  content: "▼";
  height: 10px;
  margin-right: 5px;
}

.bid-adjustment {
  display: inline;
  width: 80px !important;
  padding: 0 5px;
  font-size: 0.9rem;
  height: 1.7rem;
}

.checkbox {
  position: relative;
  top: 2px;
}

.list-group-item {
  padding: .1rem 1.25rem !important;
}

.treeContainer {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.treeContainerV {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  /* flex-wrap: wrap; */
}

label {
  opacity: 0.8;
}

.no-border {
  border: none;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 1px !important;
}

.global-checkbox {
  position: relative;
  top: 2px;
}

.cpos-half {
  position: relative;
  top: 2px;
}

.cpos {
  position: relative;
  top: 4px;
}

.version-checkbox {
  position: relative;
  margin-right: 5px;
  top: 2px;
}

.no-margin {
  margin-bottom: 0;
}

.cpointer {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .-m {
    margin-right: -4rem;
  }

  .break-line>>>li .ant-tree-node-content-wrapper {
    margin-bottom: 5px;
    padding-top: 4px;
    display: inline-block !important;
    white-space: pre-wrap !important;
    line-height: 15px !important;
    width: 90% !important;
  }
}
</style>
